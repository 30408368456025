import { useSetGuidedWaxupAccessoryModal } from '../../hooks/useGuidedWaxupAccessoryModal';
import { useHasVideoBeenPlayed } from '../../hooks/videoWalkthrough.hooks';
import { useGuidedWaxupContext } from '../../state/GuidedWaxupContext';
import { VideoPlayer } from './VideoPlayer';
import { VideoSurvey } from './VideoSurvey';
import { useScreenIsMd } from '@orthly/ui';
import { Box, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const Layout = styled(Box)(({ theme }) => ({
    display: 'grid',
    width: '100%',
    padding: '24px',
    gap: '16px',
    maxWidth: '1200px',
    [theme.breakpoints.down('lg')]: {
        padding: 0,
        maxWidth: 'unset',
        gap: '8px',
    },
}));

const StyledHeader = styled(Text)(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        margin: '16px 16px 4px 16px',
    },
}));

interface GuidedWaxupVideoWalkthroughInnerProps {
    url: string;
}

export const GuidedWaxupVideoWalkthroughInner: React.VFC<GuidedWaxupVideoWalkthroughInnerProps> = ({ url }) => {
    const { selectedDesignRevisionAlreadyReviewed } = useGuidedWaxupContext();
    const screenIsMd = useScreenIsMd();
    const { hasPlayed, markHasPlayed } = useHasVideoBeenPlayed();
    const setModal = useSetGuidedWaxupAccessoryModal();

    const hasMounted = React.useRef(false);
    const hasPlayedRef = React.useRef(hasPlayed); // To track the previous state of hasPlayed

    // Ensure hasMounted is set to true after initial render
    React.useEffect(() => {
        hasMounted.current = true;
        return () => {
            if (hasMounted.current && !hasPlayedRef.current && url) {
                setModal('vdr-abandonment'); // Show modal only if video hasn't been played and URL exists
                markHasPlayed();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Track the previous state of hasPlayed
    React.useEffect(() => {
        hasPlayedRef.current = hasPlayed;
    }, [hasPlayed]);

    return (
        <Layout>
            {!selectedDesignRevisionAlreadyReviewed && (
                <StyledHeader variant={screenIsMd ? 'body1' : 'h6'} medium>
                    Watch a Lab Technician walkthrough of your most recent design changes
                </StyledHeader>
            )}

            <VideoPlayer url={url} />

            <VideoSurvey />
        </Layout>
    );
};

export const GuidedWaxupVideoWalkthrough: React.VFC = () => {
    const { guidedVideoWalkthroughUrl } = useGuidedWaxupContext();

    if (!guidedVideoWalkthroughUrl) {
        return null;
    }

    return <GuidedWaxupVideoWalkthroughInner url={guidedVideoWalkthroughUrl} />;
};
