import { videoDesignReviewVideoPlayedAtom } from '../state/GuidedWaxup.atoms';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupAction, useGuidedWaxupSelector } from '../state/GuidedWaxupState';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { useAtom } from 'jotai';
import React from 'react';

export enum VideoSurveyOption {
    Yes = 'Yes',
    No = 'No',
}

export enum VideoAbandonmentReason {
    NotExplainedClearly = 'The changes weren’t explained clearly',
    TooLong = 'The video was too long',
    TooShort = 'The video was too short or lacked detail',
    DidNotAddressConcerns = 'The video didn’t address my concerns',
    PreferWritten = 'I prefer written explanation over video',
    Other = 'Other',
}

export const useHasVideoBeenPlayed = () => {
    const { selectedDesignRevisionId, order } = useGuidedWaxupContext();

    const [videoHasPlayed, setVideoHasPlayed] = useAtom(videoDesignReviewVideoPlayedAtom);
    const key = `${order.id}-${selectedDesignRevisionId}`;

    const hasPlayed = videoHasPlayed[key] ?? false;

    const markHasPlayed = () => {
        setVideoHasPlayed((prev: Record<string, boolean>) => ({
            ...prev,
            [key]: true,
        }));
    };

    return { hasPlayed, markHasPlayed };
};

export const useVideoControls = () => {
    const { selectedDesignRevisionId, order, vdrRequestedByDoctor } = useGuidedWaxupContext();
    const { hasPlayed, markHasPlayed } = useHasVideoBeenPlayed();

    const [duration, setDuration] = React.useState<number>(0);

    const args = React.useMemo(() => {
        return {
            $groups: {
                order: order.id,
            },
            revisionId: selectedDesignRevisionId,
            totalVideoDuration: duration,
            requestedByDoctor: !!vdrRequestedByDoctor,
        };
    }, [order.id, selectedDesignRevisionId, duration, vdrRequestedByDoctor]);

    const onPlay = () => {
        if (!hasPlayed) {
            markHasPlayed();
        }
        BrowserAnalyticsClientFactory.Instance?.track(
            'Practice - Guided Waxup - Video Design Review - Video Played',
            args,
        );
    };

    const onPause = () => {
        BrowserAnalyticsClientFactory.Instance?.track(
            'Practice - Guided Waxup - Video Design Review - Video Paused',
            args,
        );
    };

    const onEnded = () => {
        BrowserAnalyticsClientFactory.Instance?.track(
            'Practice - Guided Waxup - Video Design Review - Video Finished',
            args,
        );
    };

    const onDuration = (duration: number) => {
        setDuration(duration);
    };

    React.useEffect(() => {
        BrowserAnalyticsClientFactory.Instance?.track(
            'Practice - Guided Waxup - Video Design Review - Video Displayed',
            args,
        );
    }, [args]);

    return {
        onPlay,
        onPause,
        onEnded,
        onDuration,
    };
};

export const useVideoSurveyData = () => {
    const setNotes = useGuidedWaxupAction('SET_PRESET_REJECTION_NOTES');
    const videoWalkthroughPreset = useGuidedWaxupSelector(
        state => state.presets[LabsGqlGuidedWaxupPresetType.VideoWalkthrough],
    );
    const [selectedOption, setSelectedOption] = React.useState<VideoSurveyOption | null>(() => {
        if (videoWalkthroughPreset?.notes === 'Yes') {
            return VideoSurveyOption.Yes;
        } else if (videoWalkthroughPreset?.notes) {
            return VideoSurveyOption.No;
        }
        return null;
    });
    const [reason, setReason] = React.useState<string>(() => {
        if (videoWalkthroughPreset?.notes && videoWalkthroughPreset.notes !== 'Yes') {
            return videoWalkthroughPreset.notes;
        }
        return '';
    });

    React.useEffect(() => {
        if (videoWalkthroughPreset?.notes === 'Yes') {
            setSelectedOption(VideoSurveyOption.Yes);
            setReason('');
        } else if (videoWalkthroughPreset?.notes) {
            setSelectedOption(VideoSurveyOption.No);
            setReason(videoWalkthroughPreset.notes);
        }
    }, [videoWalkthroughPreset]);

    const onVideoIsHelpfulChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedOption = e.target.value as VideoSurveyOption;

        setSelectedOption(updatedOption);
        setReason('');

        if (updatedOption === VideoSurveyOption.Yes) {
            setNotes({ presetName: LabsGqlGuidedWaxupPresetType.VideoWalkthrough, rejectionNotes: 'Yes' });
        } else if (updatedOption === VideoSurveyOption.No) {
            setNotes({ presetName: LabsGqlGuidedWaxupPresetType.VideoWalkthrough, rejectionNotes: '' });
        }
    };

    const onVideoNotHelpfulReasonChange = (value: string) => {
        setReason(value);
        setNotes({ presetName: LabsGqlGuidedWaxupPresetType.VideoWalkthrough, rejectionNotes: value });
    };

    return {
        selectedOption,
        reason,
        onVideoIsHelpfulChange,
        onVideoNotHelpfulReasonChange,
    };
};
