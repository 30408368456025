import { GUIDED_WAXUP_COMMON_STYLES } from '../../VisualConstants.util';
import { useVideoSurveyData, VideoSurveyOption, VideoAbandonmentReason } from '../../hooks/videoWalkthrough.hooks';
import { useGuidedWaxupContext } from '../../state/GuidedWaxupContext';
import { FormControlLabel } from '@mui/material';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { SimpleSelect } from '@orthly/ui';
import { useScreenIsMd } from '@orthly/ui';
import { Box, Text, styled } from '@orthly/ui-primitives';
import { Radio, RadioGroupPrimitive as RadioGroup } from '@orthly/ui-primitives';
import React from 'react';

const SurveyLayout = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('lg')]: {
        padding: '8px 16px 16px 16px',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto auto',
        gap: '24px',
    },
}));

const SurveySection = styled(Box)(({ theme }) => ({
    display: 'grid',
    gap: '16px',
    height: 'fit-content',
    [theme.breakpoints.down('lg')]: {
        gap: '8px',
    },
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
    flexWrap: 'wrap',
    [theme.breakpoints.down('lg')]: {
        flexWrap: 'nowrap',
    },
}));

const StyledRadioButton = styled(FormControlLabel)({
    borderRadius: '16px',
    paddingRight: '20px',
    marginLeft: 0,
    ...GUIDED_WAXUP_COMMON_STYLES,
});

const ActionText = styled(Text)({
    cursor: 'pointer',
});

export const VideoSurvey: React.FC = () => {
    const { selectedOption, reason, onVideoIsHelpfulChange, onVideoNotHelpfulReasonChange } = useVideoSurveyData();
    const { setSelectedTab, selectedDesignRevisionId, vdrRequestedByDoctor, selectedDesignRevisionAlreadyReviewed } =
        useGuidedWaxupContext();
    const screenIsMd = useScreenIsMd();

    const onSkipToReviewAction = () => {
        BrowserAnalyticsClientFactory.Instance?.track(
            'Practice - Guided Waxup - Video Design Review - Add Notes Clicked',
            {
                $groups: {
                    order: 'orderId',
                },
                revisionId: selectedDesignRevisionId,
                requestedByDoctor: !!vdrRequestedByDoctor,
            },
        );

        setSelectedTab(LabsGqlGuidedWaxupPresetType.GeneralView);
    };

    if (selectedDesignRevisionAlreadyReviewed) {
        return null;
    }

    return (
        <SurveyLayout>
            <SurveySection>
                <Text variant={screenIsMd ? 'body2' : 'body1'} medium>
                    Was this video helpful?
                </Text>

                <StyledRadioGroup row defaultValue={''} value={selectedOption} onChange={onVideoIsHelpfulChange}>
                    <StyledRadioButton
                        value={VideoSurveyOption.Yes}
                        control={<Radio color={'secondary'} />}
                        label={
                            <Text variant={'body2'} medium>
                                Yes
                            </Text>
                        }
                    />
                    <StyledRadioButton
                        value={VideoSurveyOption.No}
                        control={<Radio color={'secondary'} />}
                        label={
                            <Text variant={'body2'} medium>
                                No
                            </Text>
                        }
                    />
                </StyledRadioGroup>
            </SurveySection>

            {selectedOption === VideoSurveyOption.No && (
                <SurveySection>
                    <Text variant={screenIsMd ? 'body2' : 'body1'} medium>
                        Why wasn't the video helpful?
                    </Text>

                    <Box>
                        <SimpleSelect
                            value={reason}
                            label={'Make a selection'}
                            options={Object.values(VideoAbandonmentReason).map(value => ({ label: value, value }))}
                            onChange={value => onVideoNotHelpfulReasonChange(value ?? '')}
                        />
                        {reason && (
                            <Text variant={'body2'} color={'GRAY'}>
                                Want to reject the design? Add notes{' '}
                                <ActionText
                                    component={'span'}
                                    variant={'body2'}
                                    medium
                                    color={'PRIMARY_FOREGROUND'}
                                    onClick={onSkipToReviewAction}
                                >
                                    here
                                </ActionText>
                            </Text>
                        )}
                    </Box>
                </SurveySection>
            )}
        </SurveyLayout>
    );
};
