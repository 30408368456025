import { useFeatureFlag } from '../../../../Providers/LaunchDarkly';
import { useVideoControls } from '../../hooks/videoWalkthrough.hooks';
import { Box, styled, Icon, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';
import ReactPlayer from 'react-player';

const PlayerWrapper = styled(Box)({
    aspectRatio: '16 / 9',
    maxHeight: 'calc(100vh - 300px)',
    position: 'relative',
});

const PlayerOverlay = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    cursor: 'pointer',
    '&::before, &::after': {
        content: '""',
        position: 'absolute',
        borderRadius: '50%',
        backgroundColor: FlossPalette.WHITE,
    },
    '&::before': {
        boxShadow: '0px 5px 12.5px 0px #0000000D',
        opacity: '0.3',
        height: '120px',
        width: '120px',
    },
    '&::after': {
        height: '106px',
        width: '106px',
    },
    '&:hover&::after, &:hover': {
        opacity: '0.9',
    },
});

const PlayIcon = styled(Icon)({
    height: '70px',
    width: '70px',
    color: FlossPalette.BLACK,
    zIndex: 1,
});

const StyledReactPlayer = styled(ReactPlayer)(({ theme }) => ({
    video: {
        borderRadius: '10px',
        [theme.breakpoints.down('lg')]: {
            borderRadius: 0,
        },
    },
}));

interface VideoPlayerProps {
    url: string;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({ url }) => {
    const videoControls = useVideoControls();
    const internalPlayerRef = React.useRef<HTMLVideoElement>();
    const [overlayShown, setOverlayShown] = React.useState(true);
    const { value: enableVdrPlayOverlay = false } = useFeatureFlag('enableVdrPlayOverlay');
    const showOverlay = enableVdrPlayOverlay && overlayShown && internalPlayerRef.current;

    return (
        <PlayerWrapper>
            <StyledReactPlayer
                {...videoControls}
                controls={!showOverlay}
                url={url}
                width={'100%'}
                height={'100%'}
                ref={ref => {
                    internalPlayerRef.current = ref?.getInternalPlayer() as HTMLVideoElement;
                }}
            />
            {showOverlay && (
                <PlayerOverlay
                    onClick={async () => {
                        if (internalPlayerRef.current) {
                            await internalPlayerRef.current.play();
                            setOverlayShown(false);
                        }
                    }}
                >
                    <PlayIcon icon={'PlayArrowIcon'} />
                </PlayerOverlay>
            )}
        </PlayerWrapper>
    );
};
